@import url("~ace-builds/css/ace.css");
@import url("~ace-builds/css/theme/dreamweaver.css");
@import url("~ace-builds/css/theme/ambiance.css");
@import url("~devextreme/dist/css/dx.light.css");
@import url("~@devexpress/analytics-core/dist/css/dx-analytics.common.css");
@import url("~@devexpress/analytics-core/dist/css/dx-analytics.light.css");
@import url("~@devexpress/analytics-core/dist/css/dx-querybuilder.css");
@import url("~devexpress-dashboard/dist/css/dx-dashboard.light.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a:hover {
    @apply text-primary;
  }
}

html {
  --primary-fallback: #12265e;
  --btn-secondary-fallback: #556eb4;
  --btn-text-fallback: #ffffff;

  --primary: var(--primary-fallback);
  --btn-primary: var(--primary, var(--primary-fallback));
  --btn-secondary: var(--btn-secondary-fallback);
  --btn-text: var(--btn-text-fallback);
  --ant-color-primary: var(--primary);

  --primary-gradient: linear-gradient(
    103deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  );
}

/* width */
::-webkit-scrollbar {
  background: #e5e5e5 !important;
  width: 8px;
  height: 8px;
  border-radius: 100px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primary);
  border-radius: 100px;
  height: 40px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
