:root {
  --sc-font-roboto: "Roboto", sans-serif;
  --sc-color-dark-gray: #7d7b7b;
}

span.anticon.anticon-loading.anticon-spin.ant-spin-dot > svg {
  fill: var(--primary);
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid #9d9b9b;
  background: #fff;
  color: var(--sc-color-dark-gray);
  font-size: 18px;
  height: 45px;
  padding: 12px 13px;
  font-family: var(--sc-font-roboto);
}

.ant-input-affix-wrapper > input.ant-input {
  height: unset;
}

.ant-input-affix-wrapper > input.ant-input:focus {
  border: none !important;
}

.ant-input-affix-wrapper > input.ant-input:hover {
  border: none !important;
}

.ant-input {
  border-radius: 4px;
  border: 1px solid #9d9b9b;
  background: #fff;
  color: var(--sc-color-dark-gray);
  font-size: 18px;
  height: 45px;
  padding: 12px 13px;
  font-family: var(--sc-font-roboto);
}
.ant-input:hover,
.ant-input:focus,
.ant-input-affix-wrapper {
  border: 1px solid #9d9b9b !important;
  box-shadow: none !important;
  outline: 0;
}
.ant-input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: var(--sc-color-dark-gray);
}
.ant-input::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--sc-color-dark-gray);
}
.ant-input:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--sc-color-dark-gray);
}
.ant-input:-moz-placeholder {
  /* Firefox 18- */
  color: var(--sc-color-dark-gray);
}
.ant-select.ant-select-single {
  height: 45px;
  width: 100%;
}
.ant-select .ant-select-selector {
  border-radius: 4px;
  border: 1px solid #9d9b9b;
  background: #fff;
  box-shadow: none !important;
  border-color: #9d9b9b !important;
}
.ant-select
  .ant-select-selector
  .ant-select-selection-search
  .ant-select-selection-search-input {
  font-size: 18px;
  font-family: var(--sc-font-roboto);
}
.ant-select .ant-select-selector .ant-select-selection-placeholder {
  font-size: 18px;
  font-family: var(--sc-font-roboto);
}
.ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 18px;
  font-family: var(--sc-font-roboto);
  text-align: left;
}
.ant-select-item .ant-select-item-option-content {
  font-size: 18px;
  font-family: var(--sc-font-roboto);
  text-transform: capitalize;
}
.ant-select.ant-select-focused,
.ant-select.ant-select-focused.ant-select-open,
.ant-select:hover .ant-select-selector {
  border-color: #9d9b9b;
  box-shadow: none;
  --tw-ring-color: #9d9b9b;
}
.ant-select .ant-select-arrow {
  background-image: url("../assets/images/svg/down-arrow.svg");
  background-size: cover;
  width: 18px;
  background-position: right 12px;
  background-repeat: round;
}
.ant-select .ant-select-arrow .anticon svg {
  display: none;
}
.ant-form-item {
  margin-bottom: 0;
}

.ant-modal .ant-modal-close:hover {
  background: none !important ;
}
.ant-modal .ant-modal-body.modal-content p {
  color: var(--sc-color-dark-gray);
  font-size: 18px;
  margin-bottom: 20px;
  text-align: justify;
  font-family: var(--sc-font-roboto);
}
.ant-modal .ant-modal-body.modal-content .terms-custom-style p {
  text-align: left;
}
.ant-modal .ant-modal-body.modal-content p:last-child {
  margin-bottom: 0;
}
.ant-modal .ant-modal-body.modal-content h3 {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary);
  margin-bottom: 12px;
  font-family: var(--sc-font-roboto);
}
.ant-checkbox .ant-checkbox-inner {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 2px solid var(--primary);
}
.ant-checkbox .ant-checkbox-inner:after {
  width: 6px;
  height: 10px;
  top: 45%;
  border-color: var(--primary);
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: transparent;
  border-color: var(--primary);
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: var(--primary);
  background-color: transparent !important;
}
.ant-checkbox-checked:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  background-color: transparent !important;
  border-color: var(--primary) !important;
}
.ant-checkbox + span {
  padding-left: 16px;
  text-align: left;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled)
  .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: var(--primary) !important;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-inner {
  border-color: var(--primary) !important;
}

.ant-form-item .ant-form-item-explain-error {
  color: #f50d0d !important;
  padding-left: 15px;
  font-family: var(--sc-font-roboto);
  font-size: 12px;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  border-color: #f50d0d !important;
}
.ant-notification-notice-message {
  font-size: 18px !important;
  color: var(--primary) !important;
  font-family: var(--sc-font-roboto);
  padding-right: 25px;
}
.ant-btn-default:disabled {
  background-color: #a6a8ab !important;
}
.ant-btn-default:disabled:hover,
.ant-btn-default:disabled:focus {
  background-color: #a6a8ab !important;
}
.ant-btn-default:disabled:hover span,
.ant-btn-default:disabled:focus span {
  color: #fff;
}
.ant-btn-default:disabled span {
  color: #fff;
}
.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border-color: var(--primary);
}
.ant-dropdown-trigger .ant-space-item .anticon-caret-down {
  color: var(--primary);
}

.ant-dropdown .ant-dropdown-menu {
  border-radius: 6px;
  border: 1px solid #b9b9b9;
  background: #fff;
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.12);
  padding: 0;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content > a {
  color: #656566;
  font-family: var(--sc-font-roboto);
  font-size: 16px;
  padding: 0 20px;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 10px 12px;
  border-top: 1px solid #b9b9b9;
  border-radius: 0 !important;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:first-child {
  border-top: none;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:last-child {
  border-radius: 0;
  margin-bottom: 0;
}

.ant-progress .ant-progress-inner {
  border-radius: 24px;
  background: #f0f0f0;
}
.ant-progress .ant-progress-bg {
  height: 8px !important;
  border-radius: 24px 0px 0px 24px;
  background: linear-gradient(94deg, var(--primary) 12.01%, #556eb4 142.52%);
}
.ant-progress .ant-progress-text {
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  font-family: var(--sc-font-roboto);
  color: #4b4b4d6b;
}
.ant-drawer .ant-drawer-header {
  border-bottom: 1px solid var(--primary);
}
.ant-drawer-header-title {
  flex-direction: row-reverse;
  align-items: flex-start !important;
}
.ant-drawer .ant-drawer-close {
  margin: 0;
  color: var(--primary);
}
.ant-drawer .ant-drawer-body {
  padding: 0 !important;
}
.ant-drawer .ant-menu .ant-menu-item {
  border-radius: 0 !important;
  margin: 0 !important;
}

.ant-drawer
  .ant-menu-inline.ant-menu-root
  .ant-menu-item
  > .ant-menu-title-content {
  color: #000 !important;
  font-size: 16px !important;
  font-family: var(--sc-font-roboto);
}

.ant-drawer
  .ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title
  > .ant-menu-title-content {
  color: #000 !important;
  font-size: 16px !important;
  font-family: var(--sc-font-roboto);
  margin-left: 0;
}
.ant-drawer
  .ant-menu-inline
  .ant-menu-sub.ant-menu-inline
  .ant-menu-item-group-title {
  color: #000 !important;
  font-size: 16px !important;
  font-family: var(--sc-font-roboto);
  padding-inline-start: 48px;
}

.ant-drawer .ant-menu-light .ant-menu-item-selected {
  background: linear-gradient(103deg, var(--primary) 11.81%, #556eb4 126.79%);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.ant-drawer
  .ant-menu-inline.ant-menu-root
  .ant-menu-item.ant-menu-item-selected
  > .ant-menu-title-content {
  color: #fff !important;
}
.ant-drawer .ant-menu-inline .ant-menu-item {
  height: 45px !important;
  width: 100% !important;
}
.ant-drawer .ant-menu-inline .ant-menu-submenu-title {
  width: 100% !important;
  margin: 0 !important;
}
.ant-drawer
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-submenu-title:active {
  border-radius: 0 !important;
  background: linear-gradient(103deg, var(--primary) 11.81%, #556eb4 126.79%);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.ant-drawer
  .ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title:active
  > .ant-menu-title-content {
  color: #fff !important;
}
.ant-drawer
  .ant-menu-inline.ant-menu-root
  .ant-menu-submenu-title:active
  > .ant-menu-title-content
  .ant-menu-submenu-arrow {
  color: #fff !important;
}
.ant-drawer
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-submenu-title:hover {
  border-radius: 0 !important;
  background: linear-gradient(103deg, var(--primary) 11.81%, #556eb4 126.79%);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.ant-drawer
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-submenu-title:hover
  .ant-menu-title-content {
  color: #fff !important;
}

.ant-menu
  .ant-menu-submenu-inline
  .ant-menu-submenu-title
  .ant-menu-submenu-arrow {
  color: var(--primary) !important;
}
.ant-menu
  .ant-menu-submenu-inline
  .ant-menu-submenu-title:hover
  .ant-menu-submenu-arrow {
  color: #fff !important;
}
.ant-drawer .ant-menu .ant-menu-submenu-arrow {
  color: #fff !important;
}
.ant-drawer
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  border-radius: 0 !important;
  background: linear-gradient(103deg, var(--primary) 11.81%, #556eb4 126.79%);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.ant-drawer
  .ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover
  .ant-menu-title-content {
  color: #fff !important;
}
.ant-drawer .ant-menu .ant-menu-submenu-title .ant-menu-item-icon {
  display: none !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  font-size: 16px;
  color: #656566;
}

.ant-switch {
  width: 60px;
  height: 28px;
  border-radius: 25px;
  border: none !important;
  background: #d4d4d4 !important;
}
.ant-switch:hover {
  background: #aaa;
}
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: var(--primary) !important;
}
.ant-switch.ant-switch-checked {
  background: var(--primary) !important;
}
.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgba(18, 38, 94, 0.5) !important;
}
.ant-switch .ant-switch-handle {
  width: 24px;
  height: 24px;
  top: 1px;
  inset-inline-start: 3px;
}
.ant-switch .ant-switch-handle::before {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 100px;
  top: 3px;
  left: 2px;
}
.ant-switch.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 26px);
}

.ant-form-item .ant-form-item-label label.ant-form-item-no-colon {
  font-size: 18px;
  color: var(--primary);
  font-family: var(--sc-font-roboto);
}
.ant-form-item-label-show .ant-form-item-label > label {
  font-family: var(--sc-font-roboto) !important;
  color: #424242 !important;
  font-size: 16px !important;
}
.ant-form-item .ant-form-item-label > label::after {
  margin: 0 !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0 !important;
  display: inline-flex !important;
}
.ant-collapse-custom-collapse .ant-collapse-item {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 8px;
}
.ant-collapse-custom-collapse .ant-collapse-item .ant-collapse-header {
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 4px;
}
.ant-collapse-custom-collapse .ant-collapse-content {
  border-top: none;
}
.ant-collapse-custom-collapse .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  height: 33px !important;
}
.ant-collapse.ant-collapse-icon-position-end
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-expand-icon {
  order: 9999;
}
.ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  margin-inline-end: inherit !important;
}
.ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
}
.ant-collapse-borderless > .ant-collapse-item:last-child {
  margin-bottom: 0 !important;
}

.ant-upload-wrapper .ant-upload-drag {
  border-radius: 10px;
  border: 1px solid #9d9b9b;
  background: #fff;
}
.ant-upload-wrapper .ant-upload-drag:not(.ant-upload-disabled):hover {
  border: 1px solid #9d9b9b;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload {
  padding: 33px 20px;
}
.ant-upload-wrapper
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--primary) !important;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  color: #939090 !important;
  font-size: 16px;
  font-family: var(--sc-font-roboto);
}
.ant-picker-dropdown {
  font-family: var(--sc-font-roboto);
}
.ant-picker {
  height: 45px;
  border: 1px solid #9d9b9b;
  width: 100%;
}
.ant-picker-focused.ant-picker {
  border: 1px solid #9d9b9b;
  box-shadow: none;
}
.ant-picker:hover {
  border: 1px solid #9d9b9b;
}
.ant-picker .ant-picker-suffix {
  color: var(--primary);
  font-size: 18px;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background: var(--primary);
}
.ant-picker-dropdown .ant-picker-header-view button:hover {
  color: var(--primary);
}
.ant-picker-dropdown .ant-picker-header button {
  color: var(--primary);
}

.ant-picker .ant-picker-input > input {
  color: var(--sc-color-dark-gray);
  font-size: 18px;
  font-family: var(--sc-font-roboto);
}
.ant-picker-dropdown .ant-picker-cell-in-view {
  color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner {
  background-color: var(--primary);
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today:hover
  .ant-picker-cell-inner {
  background-color: var(--primary) !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-today
  .ant-picker-cell-inner::before {
  border: 1px solid var(--primary);
  color: #fff !important;
}
.ant-picker-dropdown
  .ant-picker-content
  .ant-picker-cell-in-view.ant-picker-cell-today {
  color: #fff !important;
}
.ant-picker-dropdown .ant-picker-today-btn {
  color: var(--primary);
  font-weight: 500;
}
.ant-picker-dropdown .ant-picker-content th {
  color: var(--primary);
  font-weight: 500;
}
.ant-picker-dropdown .ant-picker-panel-container {
  border-radius: 14px;
}
.ant-picker .ant-picker-clear {
  color: var(--primary) !important;
}
.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
  font-size: 15px;
  font-family: var(--sc-font-roboto);
  font-weight: 500;
  letter-spacing: 0.45px;
}
.ant-picker-dropdown .ant-picker-header .ant-picker-month-btn {
  font-size: 15px;
}
.ant-picker-dropdown .ant-picker-header .ant-picker-year-btn {
  font-size: 25px;
  margin-left: 5px;
  font-weight: 500;
  letter-spacing: 0.75px;
}

.ant-picker-dropdown .ant-picker-cell-disabled {
  cursor: not-allowed !important;
  pointer-events: all;
}
.ant-timeline .ant-timeline-item-head-gray {
  background-color: #999999;
  border-color: #999999;
  border: 6px solid #999999;
  color: #999999;
}
.ant-timeline .ant-timeline-item-tail {
  border-inline-start: 2px solid #999999;
  inset-block-start: 18px;
  inset-inline-start: 5px;
  height: calc(100% - 25px);
}
.ant-timeline .ant-timeline-item-content {
  inset-block-start: -3px;
  margin-inline-start: 40px;
}
.ant-rate {
  color: #f89522;
}
.ant-tooltip .ant-tooltip-inner {
  color: #413c3c;
  font-size: 16px;
  font-family: var(--sc-font-roboto);
  padding: 20px;
  background-color: #fff;
}
.ant-tooltip .ant-tooltip-arrow:before {
  background: #fff;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  color: #4a525e;
  font-family: var(--sc-font-roboto);
  font-size: 20px;
  letter-spacing: 0.4px;
  border-bottom: 1px solid rgba(194, 197, 200, 0.7);
  padding: 8px 16px !important;
}
.ant-table-wrapper .ant-table {
  border-radius: 6px;
}
.ant-table-wrapper .ant-table-container {
  border-start-start-radius: 6px;
  border-start-end-radius: 6px;
}
.ant-table-wrapper .ant-table-pagination {
  align-items: center;
}
.even-row {
  background: #f4f7fd;
}
.ant-collapse-item {
  margin: 0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: auto;
}

.ant-table-wrapper table {
  border: 0.683px solid rgba(194, 197, 200, 0.7);
  border-bottom: none;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: 1.551px solid rgba(194, 197, 200, 0.44);
  color: #4a525e;
  font-weight: normal;
  font-size: 20px;
}
.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  padding-left: 25px;
}
.ant-table-wrapper .ant-table-tbody > tr > td:first-child {
  padding-left: 25px;
}
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-thead > tr > th {
  padding: 8px 16px !important;
}
.ant-table-container .ant-table-tbody .ant-table-measure-row > td {
  padding: 0 !important;
}
.rc-virtual-list-scrollbar {
  opacity: 0;
}
.ant-table-wrapper .ant-table.ant-table-middle .ant-table-cell {
  padding: 8px 16px;
}
.slick-slider .slick-prev,
.slick-slider .slick-next {
  width: 40px !important;
  height: 40px !important;
}
.slick-slider .slick-prev {
  left: -40px;
}
.slick-slider .slick-next {
  right: -40px;
}
.slick-slider svg.slick-arrow.slick-next.slick-disabled {
  cursor: not-allowed;
}
.ant-slider .ant-slider-handle::after {
  box-shadow: 0 0 0 4px var(--primary) !important;
  transition: none !important;
  width: 8px;
  height: 8px;
}
.ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px var(--primary) !important;
}
.ant-slider .ant-slider-track {
  background: linear-gradient(
    103deg,
    var(--primary) 11.81%,
    #556eb4 126.79%
  ) !important;
  border-radius: 1000px;

}
.ant-slider .ant-slider-handle {
  width: 12px;
  height: 12px;
}
.ant-slider .ant-slider-handle:active::after {
  box-shadow: 0 0 0 4px var(--primary);
}
.ant-slider .ant-slider-handle:hover::before,
.ant-slider .ant-slider-handle:active::before,
.ant-slider .ant-slider-handle:focus::before {
  width: 12px;
  height: 12px;
}
.ant-slider .ant-slider-handle:hover::after {
  box-shadow: 0 0 0 4px var(--primary) !important;
}

.ant-slider-horizontal .ant-slider-rail {
  border-radius: 1000px;
}
.ant-tabs .ant-tabs-ink-bar {
  background: var(--primary);
}
.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 20px;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--primary);
  font-size: inherit;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 1px solid #d4cbc0;
}
.ant-tabs .ant-tabs-tab {
  font-size: 20px;
  font-family: var(--sc-font-roboto);
  color: #858585;
}
.ant-tabs .ant-tabs-tab:hover {
  color: var(--primary);
}
.ant-steps .ant-steps-item-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-steps.ant-steps-label-vertical .ant-steps-item-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-steps .ant-steps-item-tail::after {
  background-color: #c1c1c1;
  height: 3px;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon {
  background-color: #ffff;
  border-color: transparent;
  border: 3px solid #c1c1c1;
}
.ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #c1c1c1;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (min-width: 768px) and (max-width: 1920px) {
  .ant-steps.ant-steps-label-vertical .ant-steps-item-tail {
    margin-inline-start: 65px;
    top: 20px;
  }
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon {
  border-radius: 1250px;
  border: 3px solid var(--primary);
  background: #f8f8fc;
  color: var(--primary);
  --tw-ring-color: none;
  --tw-ring-offset-shadow: none;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary);
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
}
.ant-steps.ant-steps-label-vertical .ant-steps-item-content {
  width: 100%;
  text-align: left;
}
.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
}
.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  font-size: 14px;
  color: #2d2b29;
  opacity: 0.7;
  font-weight: 500;
}
.ant-steps .ant-steps-item-description {
  visibility: hidden;
}
.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  width: 3px;
  background: #c1c1c1;
}
.ant-steps.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  inset-inline-start: 24px;
  top: 11px;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: var(--primary);
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon {
  background-color: var(--primary);
  border-color: var(--primary);
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #f8f8fc;
  font-size: 27px;
  font-weight: bold;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-title {
  color: #2d2b29 !important;
}
.ant-steps
  .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
  > .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon {
  border-color: #c1c1c1 !important;
}
.ant-steps
  .ant-steps-item.ant-steps-item-wait
  .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #c1c1c1 !important;
}
.ant-steps
  .ant-steps-item.ant-steps-item-finish
  .ant-steps-item-container[role="button"]:hover
  .ant-steps-item-icon
  .ant-steps-icon {
  color: #f8f8fc !important;
}
.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #2d2b29 !important;
  font-weight: 500;
  opacity: 0.7;
}
@media screen and (min-width: 320px) and (max-width: 1600px) {
  .ant-input {
    font-size: 16px;
  }
  .ant-select-item .ant-select-item-option-content {
    font-size: 16px;
  }
  .ant-select .ant-select-selector .ant-select-selection-item {
    font-size: 16px;
  }
  .ant-picker .ant-picker-input > input {
    font-size: 16px;
  }
  .ant-modal .ant-modal-title {
    font-size: 20px;
  }
  .ant-modal .ant-modal-body.modal-content p {
    font-size: 16px;
  }
  .ant-modal .ant-modal-body.modal-content h3 {
    font-size: 20px;
  }
  .ant-select .ant-select-selector .ant-select-selection-placeholder {
    font-size: 16px;
  }
  .custom-form .ant-form-item-control-input .ant-input {
    font-size: 16px !important;
  }
  .num-btn span {
    font-size: 26px !important;
  }
  .ant-select
    .ant-select-selector
    .ant-select-selection-search
    .ant-select-selection-search-input {
    font-size: 16px;
  }
  .ant-table-wrapper .ant-table-thead > tr > th {
    font-size: 16px;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-size: 16px !important;
  }
  .table-progressbar.ant-progress .ant-progress-text {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .ant-modal {
    width: 60%;
    max-width: 60%;
  }
  .ant-modal .ant-modal-content {
    padding: 25px !important;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .ant-modal {
    width: 70%;
    max-width: 70%;
  }
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .ant-modal {
    width: 90% !important;
    max-width: 90%;
  }
  .ant-modal .ant-modal-content {
    padding: 25px !important;
  }
  .ant-form-item .ant-form-item-label label.ant-form-item-no-colon {
    font-size: 16px;
  }
  .ant-steps .ant-steps-item-icon {
    width: 32px;
    height: 32px;
  }
  .ant-steps .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    font-size: 16px;
  }
  .ant-steps.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    inset-inline-start: 15px;
    top: 4px;
    padding: 32px 0 6px;
  }
  .ant-steps
    .ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title {
    font-size: 12px;
  }
  .ant-steps .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    font-size: 16px;
  }
  .ant-steps .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    font-size: 14px;
  }
}

.custom-form
  .ant-form-item-control-input-content
  .ant-input-password.ant-input-affix-wrapper.ant-input-affix-wrapper-status-error {
  border: 1px solid #ff4d4c !important;
}

#UserName .ant-form-item {
  transition: all 0.3s;
}

#UserName .ant-form-item.ant-form-item-has-error {
  padding-bottom: 55px;
}

.ant-input-prefix {
  margin-inline-end: 0 !important;
  color: rgb(147 144 144 / var(--tw-text-opacity));
}

.assigned-course-wrapper > div:nth-child(1) {
  display: block;
}

@media screen and (min-width: 600px) {
  .assigned-course-wrapper > div:nth-child(2) {
    display: block;
  }
}

@media screen and (min-width: 992px) {
  .assigned-course-wrapper > div:nth-child(3) {
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .assigned-course-wrapper > div:nth-child(4) {
    display: block;
  }
}

@media screen and (min-width: 1600px) {
  .assigned-course-wrapper > div:nth-child(5) {
    display: block;
  }
}
.guideTable thead tr th {
  background-color: white !important;
}
.guideRow:nth-child(odd) {
  background-color: #f4f7fd;
}
#uploadButton .importCSVButton:hover,
#uploadButton .importCSVButton:active {
  color: white !important;
}
.workforceProfileTableFilter .ant-checkbox-disabled .ant-checkbox-inner {
  background-color: rgba(0, 0, 0, 0.04);
  border-color: rgba(0, 0, 0, 0.04);
  border-color: var(--primary);
}
.workForcePageTabs .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
}
.workForceProfileTable table tbody tr:nth-child(odd) .ant-table-cell {
  background-color: #f4f7fd !important;
}
.workForceProfileTable table tbody tr:nth-child(even) .ant-table-cell {
  background-color: #ffffff !important;
}
.viewCourseInProgressTable table thead tr th,
.viewCourseCompletedTable table thead tr th {
  background-color: #f4f7fd !important;
}
.workforce-table-action-item.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover {
  background-color: #e5eefc;
}
.workforce-table-action-item.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item
  .ant-dropdown-menu-title-content
  a {
  display: flex;
}
.bulk-upload-container.ant-steps.ant-steps-label-vertical
  .ant-steps-item-content {
  width: 130px;
  text-align: center;
}
.workForceProfileTable.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  padding-left: 8px;
}

.ant-select-status-error:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #ff4d4f !important;
  border-top-color: rgb(255, 77, 79) !important;
  border-right-color: rgb(255, 77, 79) !important;
  border-bottom-color: rgb(255, 77, 79) !important;
}
.ant-form-item-control
  div:has(.ant-form-item-explain .ant-form-item-explain-error) {
  position: absolute !important;
}

.workforce-table-action-item
  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled
  .ant-dropdown-menu-title-content
  .ant-typography
  .ant-typography {
  color: rgba(0, 0, 0, 0.25);
}

.workforce-table-action-item
  .ant-dropdown-menu-item.ant-dropdown-menu-item-disabled
  .ant-dropdown-menu-title-content
  .ant-typography
  svg {
  color: #00000040;
}
.workforce-table-action-item .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 0;
}
.workforce-table-action-item
  .ant-dropdown-menu-item
  .ant-dropdown-menu-title-content
  > .ant-typography,
.workforce-table-action-item
  .ant-dropdown-menu-item
  .ant-dropdown-menu-title-content
  > button {
  padding: 20px 45px;
}
.workforce-add-user-items .ant-dropdown-menu .ant-dropdown-menu-item,
.header-user-items .ant-dropdown-menu .ant-dropdown-menu-item,
.menu-more-items .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 15px 32px 15px 12px;
}

.create-team-freeze-dropdown {
  width: 400px;
}
.create-team-freeze-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item.ant-dropdown-menu-item-selected {
  background-color: #f4f7fd;
}

/* .workForceProfileTable table tbody > tr:nth-child(2) > td.ant-table-cell { */
.workForceProfileTable.sticky-first-row table tbody > tr:nth-child(2),
.workForceProfileTable.sticky-first-row
  table
  tbody
  > tr:nth-child(2)
  > td.ant-table-cell {
  position: sticky !important;
  z-index: 10 !important;
  background: #fff;
  top: 0;
}
.workForceProfileTable.sticky-first-row .ant-table-body table {
  position: relative;
}

.ant-table-wrapper.team-members-table .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr>td, .team-members-table.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr>th{
  border-inline-end:2px solid #f0f0f0
 }

 .predefined-courses-table .ant-checkbox-checked .ant-checkbox-inner{
  background-color: #0000000a;
 }