@media screen and (min-width: 320px) and (max-width: 1024px) {
  .ant-modal .ant-modal-content.explore-model {
    padding: 0 !important;
  }
  .primary-btn {
    height: 40px;
  }
  .primary-btn span {
    font-size: 14px;
  }
  .secondary-btn {
    height: 40px;
  }
  .secondary-btn span {
    font-size: 14px;
  }
  .gray-btn {
    height: 40px !important;
  }
  .gray-btn span {
    font-size: 14px !important;
  }
  .ant-form-item-label-show .ant-form-item-label > label {
    font-size: 14px !important;
  }
  .ant-switch {
    height: 26px;
  }
  .ant-switch .ant-switch-handle::before {
    top: 2px;
    width: 20px;
    height: 20px;
  }
}
@media screen and (min-width: 320px) and (max-width: 480px) {
  .ant-upload-wrapper .ant-upload-drag .ant-upload {
    padding: 20px 5px;
    height: 35px;
  }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
  .secondary-btn {
    height: 40px;
  }
  .slick-slider .slick-prev {
    left: -33px;
  }
  .slick-slider .slick-next {
    right: -33px;
  }
  .slick-dots {
    bottom: -15px !important;
  }
}
@media screen and (min-width: 320px) and (max-width: 1600px) {
  .course-panel .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding-right: 20px !important;
  }
  .course-panel
    .ant-collapse.ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-expand-icon {
    height: 28px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .course-content-panel .ant-collapse-borderless > .ant-collapse-item {
    display: inherit;
    grid-auto-rows: 50px;
  }
  .course-panel .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    margin-bottom: 0;
  }
  .course-panel
    .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    width: 20px;
  }
  .purchase-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: flex-start;
  }
  .ant-table-wrapper .ant-table-tbody > tr > td {
    font-size: 14px !important;
  }
  .course-tab.ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  .responsive-table-container {
    overflow-x: scroll;
  }
}

@media (max-width: 768px) {
  .mobile-filter-container {
    position: fixed;
    background: white;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    padding: 0;
    overflow: scroll;
  }
}
