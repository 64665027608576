@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("../../public/fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

:root {
  --sc-font-roboto: "Roboto", sans-serif;
  --sc-color-dark-gray: #7d7b7b;
}

body {
  font-family: var(--sc-font-roboto);
  margin: 0;
}
.primary-btn {
  border-radius: 50px;
  background: linear-gradient(
    103.26deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  );
  color: var(--btn-text) !important;
  height: 50px;
  border: 1px solid var(--btn-primary);
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
}
.secondary-btn {
  border-radius: 50px;
  background: #ffff;
  color: var(--primary);
  border: 1px solid var(--btn-primary);
  height: 50px;
}
.danger-btn {
  background: #f50d0d;
  color: #fff;
  height: 50px;
  border: 1px solid #f50d0d;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  border-radius: 35px;
}
.danger-btn:hover,
.danger-btn:focus {
  background: #f50d0d !important;
  border: 1px solid #f50d0d !important;
  color: #fff !important;
}
.primary-btn:hover,
.primary-btn:focus {
  background: linear-gradient(
    103.26deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  ) !important;
  color: var(--btn-text) !important;
  border: 1px solid var(--btn-primary) !important;
}
.primary-btn-icon {
  border-radius: 35px;
  background: linear-gradient(
    103.26deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  );
  color: #fff;
  height: 50px !important;
  border: none;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}

.primary-btn:disabled {
  background: linear-gradient(103.26deg, #777777 11.81%, #aaaaaa 126.79%);
  border-color: #a6a8ab !important;
}
.primary-btn:disabled:hover {
  background: linear-gradient(
    103.26deg,
    #777777 11.81%,
    #aaaaaa 126.79%
  ) !important;
}
.primary-btn span {
  font-size: 16px;
  font-weight: bold;
  font-family: var(--sc-font-roboto);
}
.secondary-bg-btn {
  background: linear-gradient(
    103deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  );
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.secondary-bg-btn.ant-btn:hover {
  color: #fff;
  background: linear-gradient(
    103deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  ) !important;
}
.secondary-bg-btn.secondary-btn.ant-btn:hover span {
  color: var(--btn-text) !important;
}

.secondary-bg-btn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  color: var(--btn-text) !important;
}
.secondary-bg-btn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--btn-text) !important;
}

.secondary-btn.ant-btn:hover,
.secondary-btn.ant-btn:focus {
  background-color: #fff !important;
  color: var(--primary) !important;
  border: 1px solid var(--btn-primary) !important;
}
.secondary-btn.ant-btn:hover span {
  color: var(--primary) !important;
}
.secondary-btn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):active {
  color: var(--primary) !important;
}
.secondary-btn.ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--primary) !important;
}
.gray-btn.ant-btn:hover,
.gray-btn.ant-btn:focus {
  background: #d4d5d6 !important;
  background-color: #d4d5d6 !important;
  border: #d4d5d6 !important;
  color: var(--primary) !important;
}
.gray-btn:hover span,
.gray-btn:focus span {
  color: var(--primary) !important;
}

.ant-btn-primary:not(.secondary-btn) {
  color: var(--btn-text) !important;
  background: var(--primary-gradient) !important;
}

.ant-btn-primary {
  box-shadow: none;
}

.otp-number .ant-input {
  width: 50px;
  border: 1px solid #9d9b9b;
}
.custom-form .ant-input {
  height: 45px;
  color: var(--sc-color-dark-gray);
  border-radius: 4px !important;
  font-size: 16px !important;
}

.custom-form
  .ant-form-item-control-input-content
  .ant-input-password.ant-input-affix-wrapper {
  border: 1px solid #ced4da !important;
  border-radius: 4px;
  border-left: none;
  border-right: none;
  height: 45px;
}

.custom-form
  .ant-form-item-control-input-content
  .ant-input-affix-wrapper
  > input.ant-input {
  border: none !important;
  height: auto;
}
.custom-form .ant-input-affix-wrapper.ant-input-password {
  position: relative;
}
.custom-form .ant-input-suffix {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0px;
  border-radius: 0px 4px 4px 0px;
  background: #e0e0e0;
  width: 40px;
  height: 43px;
  display: flex;
  justify-content: center;
}

.custom-form
  .ant-input-suffix
  span.anticon.anticon-eye.ant-input-password-icon {
  color: var(--sc-color-dark-gray) !important;
}

.link-btn {
  border: none;
}
.link-btn span {
  font-size: 16px;
  color: var(--primary);
  font-family: var(--sc-font-roboto);
}
.link-btn.underline span {
  text-decoration: underline;
}
.link-btn:hover,
.link-btn:focus {
  background: none !important;
}
.link-btn:hover span,
.link-btn:focus span {
  color: var(--primary) !important;
}

.num-btn {
  border: none;
  padding: 0;
  background: none;
  box-shadow: none !important;
  transition: none !important;
  outline: none !important;
}
.num-btn:hover,
.num-btn:focus {
  background: none !important;
  color: var(--primary);
}
.num-btn:hover span,
.num-btn:focus span {
  color: var(--primary);
}
.num-btn span {
  font-size: 32px;
  color: var(--primary);
  font-weight: normal;
  font-family: "Inter", sans-serif;
}
.back-btn {
  border: none;
  padding: 0;
  background: none;
  box-shadow: none !important;
  transition: none !important;
  outline: none !important;
}
.back-btn span {
  font-size: 18px;
  color: #837e7e;
  font-weight: 500;
}
.back-btn:hover,
.back-btn:focus {
  background: none !important;
  color: #837e7e;
}

.custom-registration-popup.ant-modal {
  padding: 0;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.18);
}
.custom-registration-popup.ant-modal .ant-modal-content {
  border: none;
}
.custom-registration-popup.ant-modal .ant-modal-body {
  height: auto;
}

.flip-card {
  overflow: hidden;
  perspective: 1000px;
}
.flip-card-inner {
  display: grid;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
  grid-area: 1/1;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  cursor: pointer;
}
.flip-card .flip-card-inner p strong {
  font-weight: 600 !important;
}

.flip-card-back {
  transform: rotateY(180deg);
}
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}
header {
  transition: padding 0.3s;
}
.sticky {
  z-index: 999;
}
.sticky header {
  padding: 8px 24px;
}
header svg {
  transform: scale(1);
  transition:
    transform 0.3s,
    width 0.3s;
}
header
  .profile-menu
  .ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:last-child {
  border-top: 1px solid #b9b9b9;
}
/*  this is temp solution  */
header .profile-menu .ant-image-mask {
  display: none;
  pointer-events: none;
}
.ant-affix {
  height: auto !important;
}
.ant-affix header svg.small {
  transform: scale(0.8);
  height: 50px;
}
.checkbox-text .ant-form-item .ant-form-item-label > label {
  color: #b4b1b1;
  font-size: 16px;
  font-weight: 500;
  font-family: var(--sc-font-roboto);
  margin-right: 10px;
}
.cutsom-file-upload .ant-upload-wrapper .ant-upload-drag {
  border: none;
}
.cutsom-file-upload
  .ant-upload-wrapper
  .ant-upload-drag:not(.ant-upload-disabled):hover {
  border: none;
}
.help-support-accordion
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  display: flex !important;
  padding-right: 25px !important;
  align-items: center;
}
.help-support-accordion .ant-collapse-item {
  margin-bottom: 0 !important;
}
.my-background {
  background: url("../assets/images/svg/online-webinars.svg") center/cover;
}

/*stepper*/
.step {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.v-stepper {
  position: relative;
}

.step .circle {
  background-color: #949499;
  border: 2px solid #949499;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  display: inline-block;
}
.step .circle:after {
  content: " ";
  position: absolute;
  display: block;
  top: -116px;
  right: 50%;
  bottom: 0;
  left: 44%;
  height: 171%;
  width: 2px;
  transform: scale(1, 2);
  transform-origin: 50% -100%;
  background-color: #949499;
}
.last-step .step .circle:after {
  display: none;
}
.step.completed .circle {
  visibility: visible;
  background-color: #949499;
  border-color: #949499;
}
.step.active .circle {
  visibility: visible;
  border-color: #949499;
}
.step.empty .circle {
  visibility: hidden;
}
.step.last-child .circle:after {
  display: none;
}
/*My courses*/

.course-panel .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border: 1px solid #959595;
  border-radius: 10px;
  align-items: center;
  width: 100%;
  padding-right: 10px !important;
  max-height: 60px;
}
.course-panel
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow {
  font-size: 30px;
  color: var(--primary);
}
.course-panel .ant-collapse-borderless {
  background: transparent !important;
}
.search-box .ant-input-affix-wrapper {
  border-radius: 8px;
  border: 1px solid #9d9b9b;
  background: #fff;
}
.search-box .ant-input-affix-wrapper > input.ant-input {
  font-size: 14px;
  color: #000;
}
.responsive-table-container {
  width: 100%;
  overflow-x: auto;
}
.table-progressbar.ant-progress {
  display: flex;
}
.table-progressbar.ant-progress .ant-progress-text {
  color: #4a525e;
  font-size: 20px;
  letter-spacing: 0.4px;
  font-family: var(--sc-font-roboto);
  font-weight: normal;
}
.course-content-panel .ant-collapse-borderless > .ant-collapse-item {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 30px;
  grid-auto-rows: 70px;
}
.course-content-panel
  .ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header {
  border: 1px solid var(--primary);
}
.course-content-panel
  .ant-collapse
  > .ant-collapse-item.ant-collapse-item-active
  > .ant-collapse-header
  .ant-collapse-header-text
  div {
  color: var(--primary) !important;
}
.search-course.ant-input {
  height: 50px;
  border-radius: 50px;
  color: #666;
  font-size: 14px;
}
.course-tab.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 45px;
}
.custom-datepicker .ant-picker {
  height: 55px;
}
.purchase-table thead {
  display: none;
}

.purchase-collapse .ant-collapse-borderless {
  background: none !important;
}
.purchase-collapse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex !important;
  align-items: center;
}
.ant-modal-wrap.block.custom-modal-wrap.ant-modal-centered {
  z-index: 99999 !important;
}
.slick-slider .slick-track {
  margin: 0 !important;
}
.course-content-panel
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-header-text {
  width: 85%;
}

.ant-switch-inner {
  padding-top: 3px;
}
.ant-switch-inner .ant-switch-inner-checked {
  font-size: 11px !important;
  font-weight: 700;
}

.ant-switch-inner .ant-switch-inner-unchecked {
  font-size: 11px !important;
  padding-left: 3px;
  font-weight: 700;
}

svg.slick-arrow.slick-prev.slick-disabled {
  opacity: 0.5;
  cursor: default;
}

svg.slick-arrow.slick-next.slick-disabled {
  opacity: 0.5;
  cursor: default;
}
/*Filter */
.filter-rate.ant-rate {
  color: #f0d326;
}
.ant-layout {
  background-color: transparent !important;
}
.filter-box-bg {
  background: none !important;
}

.filter-button .ant-btn {
  border-radius: 8px;
  border: 1px solid rgba(164, 164, 164, 0.32);
  background: #fff;
  padding: 6px 20px;
  color: #a4a4a4;
  font-size: 18px;
  box-shadow: none;
  height: 52px;
}
.filter-button
  .ant-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  border: 1px solid rgba(164, 164, 164, 0.32);
  color: #a4a4a4;
}
.filter-button .ant-space-gap-row-small {
  width: 100%;
  justify-content: space-between;
}

svg.custom.edit-disabled circle {
  fill: #d4d4d4;
}

.filter-btn .ant-btn {
  height: auto !important;
  padding: 8px 10px !important;
}
.filter-btn .ant-btn span {
  font-size: 14px !important;
}
.ant-layout .ant-layout-sider-zero-width > * {
  overflow: auto;
}

.ant-upload-list-item.ant-upload-list-item-done {
  background: transparent;
  height: 30px;
  display: flex;
  align-items: center;
}
.ant-upload-list-item-name:hover,
.ant-upload-list-item-name {
  color: var(--primary);
}

.ant-upload-list-item-actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.ant-upload-icon .anticon-paper-clip svg {
  color: var(--primary);
  width: 17px;
  height: 17px;
  margin-top: 10px;
}
.ant-upload-list-item-actions .anticon-eye svg {
  width: 22px;
  height: 22px;
  color: var(--primary);
  margin-top: 2px;
}

.pac-container.pac-logo {
  z-index: 9999;
}
.migration-header-container {
  background: linear-gradient(
    103deg,
    var(--btn-primary) 11.81%,
    var(--btn-secondary) 126.79%
  );
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.12),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.slick-dots {
  bottom: 15px !important;
}
.slick-dots li button:before {
  opacity: 0.5 !important;
  font-size: 12px !important;
  color: var(--primary) !important;
}

.slick-dots li {
  margin: 0 !important;
}

.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: var(--primary) !important;
}

.assigned-courses-collapse
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header {
  width: 100%;
  background: #f4f7fd;
  padding: 12px 16px !important;
  display: flex !important;
  align-items: center;
}

.assigned-courses-collapse .ant-collapse {
  border: 0.683px solid #c2c5c8b3;
  border-radius: 5px;
}

.purchase-collpse .ant-collapse-borderless {
  background: none !important;
}
.purchase-collpse .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  display: flex !important;
  align-items: center;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: transparent;
}

.ant-picker-dropdown .ant-picker-header {
  visibility: hidden !important;
}

.ant-picker-dropdown .ant-picker-header-view {
  display: none !important;
}
.ant-input.PhoneInput {
  padding-right: 0 !important;
  border-right: transparent;
}

